.counter {
    font-size: 2rem;
    font-weight: 600;
  }
  
  .counter__title {
    font-size: 1rem;
    font-weight: 500;
  }
  
  .background-img-enquiry{
    padding: 20px 20px 20px 20px;
    object-fit: cover;
    width: 40%;
  }

  .enquiry-section{
    background-color: transparent;
    color: black;
    margin-bottom: 40px;
    height: 100%;
  }
  
  .about__content {
    text-align: center;
    margin-top: 100px;
  }

.image-container {
  display: flex;
  justify-content: center; 
  align-items: center; 
  height: auto;
}
  
  .about__img img {
    border-radius: 15px;
  }
  
  .about__content h2 {
    font-weight: 600;
  }

  .cards-enquiry {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    margin: 40px;
  }

  .num{
    text-decoration: none;
    color: black;
  }

    .enquiry-txt{
      width: 60%;
      padding: 40px;
    }
  
  .num:hover{
    color: #6a71f1;
  }
  
  .enquiry-fa{
    color: #6a71f1;
    font-size: xx-large;
    padding-bottom: 10px;
  }

  .cards-enquiry:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  @media (max-width: 768px) {
    
    .enquiry-txt{
      width: 70%;
      padding: 0;
    }

    .background-img-enquiry{
      object-fit: cover;
      width: 100%;
    }

    .cards-enquiry{
      margin: 20px;
    }
}
