.glass-div{
    position: relative;
    top: 0;
    z-index: 100;
    width: 60%;
    height: 550px;
    padding: 20px;
    border-radius: 20px;
    background: rgb(255, 255, 255, 0.5);
    backdrop-filter: blur(7px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* Box shadow */
    margin: 0px 10px 100px 10px;
    top: 50px;
    display: flex; 
    align-items: center; 
}

.glass-div-content {
    width: 100%;  
    max-width: 100%;
    max-height: 100%; 
    overflow: auto; 
    text-align: center;
}

.inquiry{
    background-image: url('../../../assets/bg\ image.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(94, 94, 183, 0.4); /* Black w/opacity/see-through */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 600px;
}

.title-heading{

    padding: 20px;
    font-size:x-large;
    text-align: center;
    color: #6a71f1;
}

.sub-title-heading{
    padding: 20px;
    text-align: center;
}

.input-tab{
    padding: 10px;
    border-radius: 10px;
    border: none;
    width: 80%;
    margin-bottom: 10px;
}

.glass-div-content .btn-inquiry{
    margin: 5px;
}

.icon-container{
    margin: 10px;
}

.ToggleButton:focus{
    border: 2px solid #6a71f1;
}

Slider .PrivateValueLabel-circle-4 {
    width: 37px;
    height: 37px;
}

.icon-city{
    width: 80%;
    height: 80%;
}

.image-title {
    margin: 5px;
    text-align: center; 
    background-color: rgba(0, 0, 0, 0.5); 
    color: white; 
    padding: 5px; 
    font-size: 14px; 
}

.stacked-button{
    width: 30%;
}
  
  @media screen and (max-width: 768px) {
    .glass-div {
        width: 90%; 
        height: 400px; 
    }
    
      .icon-city {
        width: 70px; 
        height: 70px; 
      }
    
      .image-title {
        font-size: 12px; 
      }

      .stacked-button{
        width: 40%;
    }
  }
  

