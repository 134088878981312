.card-team {
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    width: 100%; 
    max-width: 500px; 
    height: auto; 
    font-size: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    text-align: center;
    overflow: auto;
    padding: 20px;
  }

section{
  width: 100%;
}  
  
  @media (max-width: 768px) {
    .card-team {
      font-size: 30px;
      max-width: 400px; 
      padding: 10px;
    }

    .meet-team{
      font-size: 50px;
      text-align: center;
    }

    .designation{
      font-size: small;
      font-weight: 500;
    }
  }
  
  @media (max-width: 576px) {
    .card-team {
      font-size: 20px;
      max-width: 400px; 
      padding: 10px;
    }

    .designation{
      font-size: small;
      font-weight: 500;
    }

    .meet-team{
      font-size: 30px;
      text-align: center;
    }
  }
  
  .team-icon{
    width: 20%;
  }

  .buttons{
    text-align: center;
  }

  .button{
    margin: 10px;
    background-color: white;
    border: transparent;
    padding: 7px;
    height: 50px;
    width: 50px;
    border-radius: 500px;
  }

  .main-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 80px 0px 80px 0px;
    top: 40px;
  }

  .designation{
    font-size: medium;
    font-weight: 500;
  }

  .wrapper {
    display: flex;
    margin: 20px;
  }

  .meet-team{
    font-size: 82px;
    text-align: center;
  }

  .team-title{
    padding-top: 60px;
  }

  @media screen and (max-width: 990px) {

    .team-title{
        padding-top: 0px;
    }
    .meet-team{
      font-size: 50px;
      text-align: center;
    }

    .designation{
      font-size: small;
      font-weight: 500;
    }
    
  }
  