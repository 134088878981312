.faq-row{
    margin: 10px 10px 10px 5px;
    font-weight: 500;
}

.faq{
    background-color: #EBECFD;
    padding: 40px;
}

.faq-end{
    margin-bottom: 40px;
}

.h1-faq{
    font-size: 76px;
    color: white;
    text-align: center;
    margin: 40px 20px 40px 20px;
}

@media (max-width: 650px) {
    
.faq{
    padding: 20px;
}

}