.h1Tag {
    margin-top: 40px;
    font-size: 78px;
    text-align: center;
    padding: 40px 20px 20px 20px;
    color: white;

  }

.blog-desc{
  text-align: justify;
  font-size: medium;
  font-weight: 600;
}  

.blog-css{
  background-color: #EBECFD;
  width: 100%;
}
  
  .product--image {
    width: 100%;
    height: auto;
    max-height: 16em; 
    object-fit: cover;
  }

  .card-testimonial{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    max-width: 320px;
    width: 90%; /* Adjust width to be responsive */
    margin: auto;
    text-align: center;
    font-family: arial;
    padding: 1rem;
    background-color: white;
    margin-bottom: 40px;
  }


  .card button {
    border: none;
    outline: 0;
    padding: 12px;
    color: white;
    background-color: #6a71f1;
    text-align: center;
    cursor: pointer;
    width: 100%;
    font-size: 18px;
  }
  
  .card button:hover {
    opacity: 0.7;
  }
  
  .blogs-links{
    text-decoration: none;
    color: white;
  }
  
  .Blog {
    background-color: #EBECFD;
    padding: 0 1rem;
  }
  
  .blog-section{
    background-color: #EBECFD;
  }


  
  @media screen and (max-width: 680px) {
    .card {
      max-width: 100%; 
    }
  
    .h1Tag {
      margin: 2rem;
      font-size: 35px;
      text-align: center;
    }
    
    .Blog {
      padding: 0; /* Remove left padding for smaller screens */
      
    }
  }
  
  /* Media Queries */
  @media screen and (max-width: 768px) {
    .card {
      max-width: 100%; /* Adjust card width for smaller screens */
    }
  
    .h1Tag {
      margin: 2rem;
      font-size: 40px;
      text-align: center;
    }
    
    .Blog {
      padding: 0; /* Remove left padding for smaller screens */
      margin-top: 5%
    }
  }
  
  @media screen and (max-width: 960px) {
    .h1Tag {
      margin: 2rem;
      font-size: 60px;
      text-align: center;
    }
  }
  
  @media screen and (max-width: 480px) {
    .h1Tag {
      font-size: 40px;
    }
  }