.testimonial-section{
    background-color: #EBECFD;
    margin-top: 40px;
}

.carousel-inner {
    height: 538px;
}
  
  .carousel-control-prev {
    height: 50px;
    left: unset !important;
    position: fixed !important;
    top: -5px !important;
    right: 50%;
    color: gray !important;
  }

  .carousel-control-next {
    height: 50px;
    right: unset !important;
    position: fixed !important;
    top: -5px !important;
    left: 50%;
    color: gray !important;
  }

  .testimonial-txt{
    width: 50%;
    padding: 0;
  }

  @media (max-width: 768px) {
    
    .testimonial-txt{
      width: 100%;
      padding: 40px 20px 20px 20px;
    }
}