.header-inquiry {
    width: 100%;
    height: 50px;
    line-height: 30px;
    background-color: rgba(94, 94, 183, 0.4);
  }

  .logo{
    padding-top: 10px;
  }
  
  .nav__list_enquiry {
    padding: 0px 12px 2px 12px;
    margin: 0;
    list-style: none;
    display: flex;
    column-gap: 1.3rem;
    transition: all 0.3s ease-out;
  }

  .nav-bar-seperate{
    border-radius: 30px;
    margin: 10px;
    height: 30px;
    background-color: #ebe6e6;
    }
  
  .nav__item a {
    font-weight: 500;
    font-size: 1.0rem;
    color: #0a2b1e;
    text-decoration: none;
    transition: 0.3s;
  }

  .sticky {
    position: fixed;
    top: 0;
    height: 60px;
    width: 100%;
    background-color: transparent; 
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); 
    backdrop-filter: blur(9px);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
  
  .nav__item a:hover {
    color: #6a71f1;
  }
  
  .nav__right p {
    font-weight: 500;
    color: #0a2b1e;
    font-size: 1.1rem;
  }
  
  .nav__right p i {
    font-size: 1.3rem;
  }
  
  .mobile__menu span i {
    font-size: 1.4rem;
    color: #0a2b1e;
    cursor: pointer;
  }
  
  .mobile__menu {
    display: none;
  }
  
  @media only screen and (max-width: 991px) {

    .header-inquiry {
      line-height: 60px;
  }

    .nav__menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #0a2b1ec2;
      z-index: 999;
      display: none;
      transition: all 0.3s ease-out;
    }
  
    .nav__list_enquiry {
      position: absolute;
      background: white;
      top: 0;
      right: 0;
      width: 250px;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      z-index: 999;
      padding-top: 80px;
    }
  
    .mobile__menu {
      display: block;
    }
    .nav__right {
      display: none;
    }
  
    .active__menu {
      display: block;
    }
  }