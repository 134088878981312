.counter {
    font-size: 2rem;
    font-weight: 600;
  }
  
  .counter__title {
    font-size: 1rem;
    font-weight: 500;
  }

  .about__counter{
    margin: 60px 20px 20px 20px;
  }
 
  .enquiry-section{
    background-color: transparent;
    color: black;
    margin-bottom: 40px;
  }
  
  .about__content {
    text-align: center;
    margin-top: 40px;
  }

  .about__content h2 {
    font-weight: 600;
  }

  .cards-enquiry-about {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 30px;
    border-radius: 10px;
    text-align: justify;
    margin: 40px 10px 10px 10px;
  }

  .cards-enquiry-row2 {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 30px;
    border-radius: 10px;
    text-align: justify;
    margin: 10px 10px 10px 10px;
  }

  .cards-enquiry-row2:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .num{
    text-decoration: none;
    color: black;
  }
  
  .num:hover{
    color: #6a71f1;
  }
  
  .enquiry-fa{
    color: #6a71f1;
    font-size: xx-large;
  }

  .cards-enquiry-about:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .about-btn{
    margin-left: 40px;
    margin-top: 40px;
    border-radius: 20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .card-title{
    text-align: center;
  }

  @media (max-width: 768px) {
    
    .about-btn{
      margin-left: 20px;
      margin-top: 40px;
      border-radius: 20px;
      padding-left: 20px;
      padding-right: 20px;
    }

  }