
.blog-container {
    height: 50vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: white;
}

.blog-content{
 justify-content: left;
 padding: 10px;
}

.blog-title{
    text-align: center;
    padding: 20px;
    font-size: 40px;
}

.blog-container-box{
    margin: 2%;
}

.question-box{
    text-align: center;
}

.recommended-blog{
    font-size:xx-large;
    margin: 3%;
    padding: 20px 0px 20px 0px;
}

/* Media query for screens smaller than 768px */
@media (max-width: 768px) {
    .blog-container {
        height: 40vh; /* Adjust the height for smaller screens */
        background-size: 80%;;
    }

    .blog-title{
        font-size: 24px;
    }
    
}

/* Media query for screens smaller than 480px */
@media (max-width: 480px) {
    .blog-container {
        height: 20vh; /* Further adjust the height for even smaller screens */
    }

    .recommended-blog{
        font-size: x-large;
    }
}
