@import "~font-awesome/css/font-awesome.min.css";
@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@700&display=swap');

.hero__content h2 {
    font-weight: 600;
    line-height: 55px;
  }

  section{
    height: 100%;
  }
  
  .hero__content {
    padding-top: 100px;
  }
  .search input {
    border: none;
    outline: none;
    padding: 7px 10%;
  }

  .card{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 10px;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    --animate-duration: 4s;
  }

  .row-card{
    font-size: small;
  }

  .icon-align{
    font-size:x-large;
    color: #6a71f1;
    margin: 0px 10px 0px 20px;
  }

  .icon-align-new{
    font-size:x-large;
    color: #6a71f1;
    margin: 0px 20px 0px 20px;
  }

  .icon-align-row-1{
    font-size:x-large;
    color: #6a71f1;
    margin: 0px 16px 0px 20px;
  }

  .description{
    font-size:small
  }

  h1{
    font-family: 'Space Grotesk', sans-serif;
    font-weight: 700;
  }

  .bold-text {
    font-weight: bold;
  }

  .card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }

  .search-box{
    border: 1px solid #17bf9d44;
    font-size: medium;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    margin-top: 20px;
    display: flex;
    padding: 10px 10px 10px 10px;
    background-color: #e2e3fb;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
  }

  .fa-check-circle{
    color:#6a71f1 ;
  }
  
  .search {
    border: 1px solid #17bf9d44;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    margin-top: 20px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin-right: 10px;
  }

  .call-button{
    margin-top: 20px;
    padding: 5px;
    --animate-duration: 4s;
    --animate-delay: 4s
  }

  .search-container {
    display: flex;
    align-items: center; 
  }

  .call{
    background-color: white;
    color: black;
    border: 0.5px solid wheat;
    border-radius: 30px;
    padding: 5px 10px 5px 10px;
    box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
    margin: 2px;
  }

  .btn-style{
    background-color: #6a71f1;
    border: none;
    border-radius: 30px;
    color: white;
    padding: 5px;
    margin: 2px;
    padding: 5px 20px 5px 20px
}

@media (max-width: 960px) {
    
  .search-box{
    font-size: small;
  }

}

@media (max-width: 768px) {
    
  .search-box{
      font-size: x-small;
  }

  .hero__content {
    padding-top: 60px;
  }

}

@media (max-width: 420px) {
    
  .search{
    font-size:11px;
  }

  .call{
    font-size:12px
  }

}
    