.fab {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #53d365;
    color: #fff;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    font-size: 30px;
  }

  .fabNew{
    position: fixed;
    bottom: 100px;
    right: 20px;
    background-color: blue;
    color: #fff;
    border-radius: 20%;
    width: 100px;
    height: 60px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 10000;
  }

 a {
  text-decoration: none;  
  color: inherit; 
 }
 
  @media (max-width: 768px) {
    .fab {
      font-size: 20px;
      width: 50px;
      height: 50px;
      bottom: 015x;
      right: 10px;
    }
    .fabNew{
      width: 50px;
      font-size: small;
      height: 50px;
      bottom: 80px;
      right: 15px;
    }
  }
