.choose__img img {
    border-radius: 15px;
    width: 50%;
  }

  .small-view{
    display: none;
  }
  
  .choose__content {
    padding-right: 50px;
    margin-top: 15%;
  }
  
  .choose__content h2 {
    font-weight: 600;
  }

  .right-aligned{
    padding-right: 50px;
  }
  
  .choose__img {
    position: relative;
    left: 0;
    width: 90%;
    height: 100%;
    z-index: 777;
  }

  .choose__img-last {
    position: relative;
    left: 0;
    width: 70%;
    height: 100%;
    z-index: 777;
  }

  .choose__img-first {
    position: relative;
    left: 0;
    top: 100px;
    width: 70%;
    height: 100%;
    z-index: 777;
  }

  .choose__content-first {
    padding-right: 50px;
    margin-top: 100px;
  }
  
  .play__icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    z-index: 7777;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .play__icon i {
    color: #17bf9e;
    font-size: 2rem;
    padding: 10px;
  }

  @media (max-width: 768px) {
    
    .large-view{
      display: none;
    }

    .small-view{
      text-align: center;
      display: block;
    }

    .choose__content {
      padding-right: 0px;
      margin-top: 10%;
    }

    .choose__img {
      position: relative;
      left: 0;
      width: 80%;
      height: 100%;
      z-index: 777;
    }

    .choose__img img {
      border-radius: 15px;
      width: 50%;
    }
    
    .choose__content {
      padding-right: 00px;
    }
    
    .choose__content h2 {
      font-weight: 600;
    }
    
    .choose__img {
      position: relative;
      left: 10%;
      width: 80%;
      height: 100%;
      z-index: 777;
    }
  
    .choose__img-last {
      position: relative;
      left: 10%;
      top: 0px;
      margin-bottom: 30px;
      width: 6cap;
      height: 80%;
      z-index: 777;
    }
  
    .choose__img-first {
      position: relative;
      left: 15%;
      top: 40px;

      z-index: 777;
    }
  
    .choose__content-first {
      padding-right: 50px;
      margin-top: 100px;
    }
    

  }

  @media (max-width: 990px) {
    .choose__img-last {
      position: relative;
      margin-bottom: 30px;
      width: 6cap;
      top: 0px;
      height: 90%;
      z-index: 777;
    }
  }