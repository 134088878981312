.fa-check{
    color: green;
}

.fa-times{
    color: red;
}

.table-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
}

.table-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.choose-staype{
    margin: 60px 20px 20px 20px;
}

.text-row{
    font-weight: 500;
}