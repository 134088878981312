.footer {
    background: rgba(226, 248, 248, 0.664);
    padding-top: 40px;
  }
  
  .follows span a {
    text-decoration: none;
  }
  
  .follows span {
    margin-right: 15px;
  }
  
  .link__list .link__item {
    background: transparent;
    line-height: 10px;
  }

  .footer-icon{
    align-items: center;
    justify-content: center;
    width: 15%;
  }
  
  .follows{
    text-align: center;
  }

  .link__list a {
    text-decoration: none;
    color: #0a2b1e;
    font-weight: 500;
  }

  .follow-items{
    text-align: center;
  }

 @media (max-width: 768px) {

  .fw-bold{
    margin-left: 30px;
  }

  .link__list .link__item {
    background: transparent;
    line-height: 8px;
    margin-left: 30px;
  }
  
 } 